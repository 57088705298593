<template>
    <div class="modal active" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Add To Workout</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @invalid-submit="handleFormInvalidSubmit" @submit="handleSubmitForm" v-slot="{ errors }" ref="workout-setup-form">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select A Workout</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                                    <Field autocomplete="off" type="text" name="workout" v-model="form.workout_id" :class="{ 'has-error': errors.workout }" rules="required">
                                        <multiselect
                                            v-model="form.workout_id"
                                            :options="workouts"
                                            valueProp="id"
                                            label="name"
                                            :searchable="true"
                                            placeholder="Select One"
                                            autocomplete="nofill"
                                            @search-change="searchWorkout"
                                            :loading="workoutsLoader"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="workout" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Which Block?</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.workout_block }">
                                    <Field autocomplete="off" type="text" name="workout_block" v-model="form.workout_block_id" :class="{ 'has-error': errors.workout_block }" rules="required" label="workout block" >
                                        <multiselect
                                            v-model="form.workout_block_id"
                                            :options="workoutBlocks"
                                            valueProp="id"
                                            label="name"
                                            :searchable="true"
                                            placeholder="Select One"
                                            autocomplete="nofill"
                                            :loading="workoutBlockLoader"
                                            :disabled="form.workout_id ? false : true"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="workout_block" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div class="modal_footer">
                <button :disabled="addExercisesLoader" type="button" class="btn cancel_btn mx-1" @click="closeModal()">Cancel</button>
                <button :disabled="addExercisesLoader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="addExercisesLoader"></i> {{ addExercisesLoader ? 'Adding' : 'Add' }}</button>

            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Workout Setup',

        data () {
            return {
                form: {
                    exercise_ids: '',
                    workout_id: '',
                    workout_block_id: '',
                },
            }
        },

        props: {
            modelValue: Boolean,
            selectedExercises: {
                type: Array,
                default: () => [],
            },
            refreshAsset: {
                type: Function,
                default: () => {},
            }
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = { exercise_ids: vm.selectedExercises, workout_id: '', workout_block_id: '' };

                    vm.getAllWorkouts({});
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'form.workout_id' (id) {
                const vm = this;

                vm.form.workout_block_id = '';
                const WorkoutSetupForm   = vm.$refs['workout-setup-form'];

                setTimeout(() => {
                    if (WorkoutSetupForm) {
                        WorkoutSetupForm.setFieldError('workout_block', false);
                    }
                }, 1)

                if (id) {
                    vm.getWorkoutBlocks(id);
                }
            }
        },

        computed: mapState({
            workouts: state => state.workoutModule.allWorkouts,
            workoutsLoader: state => state.workoutModule.allWorkoutLoader,
            workoutBlocks: state => state.workoutModule.workoutBlocks,
            workoutBlockLoader: state => state.workoutModule.workoutBlockLoader,
            addExercisesLoader: state => state.workoutModule.workoutBlockExerciseLoader,
        }),

        methods: {
            ...mapActions({
                getAllWorkouts: 'workoutModule/getAllWorkouts',
                getWorkoutBlocks: 'workoutModule/getWorkoutBlocks',
                addBlockExercise: 'workoutModule/addBlockExercise',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            searchWorkout (search) {
                const vm = this;

                vm.getAllWorkouts({ search });
            },

            handleSubmitForm () {
                const vm = this;

                vm.form.exercise_ids = vm.selectedExercises;

                vm.addBlockExercise(vm.form).then((result) => {
                    if (result) {
                        vm.refreshAsset();
                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>
